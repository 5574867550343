export const promptPresets = [
  {
    id: "none",
    label: "無",
    image:
      "https://r2.web.moonshine.tw/msweb/moonshotai/presets/16eafb30-bb55-4fad-a2bc-085046fe46db.webp",
  },
  {
    id: "cinematic-default",
    label: "精緻",
    image:
      "https://r2.web.moonshine.tw/msweb/moonshotai/presets/0ef11a93-85eb-4974-8260-1275819739c9.webp",
  },
  {
    id: "sai-3d-model",
    label: "3D渲染",
    image:
      "https://r2.web.moonshine.tw/msweb/moonshotai/presets/d8a1b185-8764-41ee-892b-8f7e0289dc30.webp",
  },
  {
    id: "sai-analog_film",
    label: "底片",
    image:
      "https://r2.web.moonshine.tw/msweb/moonshotai/presets/f94ff257-457c-4351-9031-4ccb4193f358.webp",
  },
  {
    id: "sai-anime",
    label: "動畫",
    image:
      "https://r2.web.moonshine.tw/msweb/moonshotai/presets/965907ee-363b-4a3e-9e1d-232a7a8fcb75.webp",
  },
  {
    id: "sai-cinematic",
    label: "電影",
    image:
      "https://r2.web.moonshine.tw/msweb/moonshotai/presets/78480f16-8eda-40a0-a0ca-b2e28ca538b5.webp",
  },
  {
    id: "sai-comic_book",
    label: "漫畫",
    image:
      "https://r2.web.moonshine.tw/msweb/moonshotai/presets/0f574321-ba33-4c98-811c-29dfd2506a86.webp",
  },
  {
    id: "sai-craft_clay",
    label: "雕塑",
    image:
      "https://r2.web.moonshine.tw/msweb/moonshotai/presets/fd4d0d94-25ec-4a90-b81a-6696e01b6630.webp",
  },
  {
    id: "sai-digital_art",
    label: "數位繪圖",
    image:
      "https://r2.web.moonshine.tw/msweb/moonshotai/presets/3f15e766-36ee-4e1c-b81e-abf9b532e9b4.webp",
  },
  {
    id: "sai-enhance",
    label: "專業",
    image:
      "https://r2.web.moonshine.tw/msweb/moonshotai/presets/e52ee737-6011-42a1-a9f0-bd2a695cd481.webp",
  },
  {
    id: "sai-fantasy_art",
    label: "奇幻",
    image:
      "https://r2.web.moonshine.tw/msweb/moonshotai/presets/5d8e9303-e90f-44b7-9d01-ec2b6ae887a6.webp",
  },
  {
    id: "sai-isometric",
    label: "等距",
    image:
      "https://r2.web.moonshine.tw/msweb/moonshotai/presets/778e1eb2-29c9-4556-9154-370bb025b6ef.webp",
  },
  {
    id: "sai-line_art",
    label: "線條",
    image:
      "https://r2.web.moonshine.tw/msweb/moonshotai/presets/dc3958c2-3ba7-40d2-b446-ace4840c6057.webp",
  },
  {
    id: "sai-lowpoly",
    label: "低多邊形",
    image:
      "https://r2.web.moonshine.tw/msweb/moonshotai/presets/1b64f254-539b-4770-a3fb-8b63a688cf05.webp",
  },
  {
    id: "sai-neonpunk",
    label: "霓虹龐克",
    image:
      "https://r2.web.moonshine.tw/msweb/moonshotai/presets/1d326f82-6812-4b8c-a3fb-b86fd3659716.webp",
  },
  {
    id: "sai-origami",
    label: "摺紙",
    image:
      "https://r2.web.moonshine.tw/msweb/moonshotai/presets/f8f088a9-c980-4da8-88a5-54b2ccbdb6eb.webp",
  },
  {
    id: "sai-photographic",
    label: "攝影",
    image:
      "https://r2.web.moonshine.tw/msweb/moonshotai/presets/3bddce6d-056f-4dfb-b39f-f93f49ff40ed.webp",
  },
  {
    id: "sai-pixel_art",
    label: "像素",
    image:
      "https://r2.web.moonshine.tw/msweb/moonshotai/presets/323a928d-a81c-45a3-8c89-5d2537385b07.webp",
  },
  {
    id: "sai-texture",
    label: "材質",
    image:
      "https://r2.web.moonshine.tw/msweb/moonshotai/presets/11071b50-0531-4fea-8e6b-2f7e5ff36a90.webp",
  },
  {
    id: "ads-advertising",
    label: "廣告",
    image:
      "https://r2.web.moonshine.tw/msweb/moonshotai/presets/d06da211-bf3d-49cc-95e0-85b28079e42a.webp",
  },
  {
    id: "ads-automotive",
    label: "汽車廣告",
    image:
      "https://r2.web.moonshine.tw/msweb/moonshotai/presets/95732d8e-7994-4ea8-a57f-1d3bec8a25aa.webp",
  },
  {
    id: "ads-corporate",
    label: "公司廣告",
    image:
      "https://r2.web.moonshine.tw/msweb/moonshotai/presets/efbc623a-44ec-426b-9cdb-a82d02c2868b.webp",
  },
  {
    id: "ads-fashion_editorial",
    label: "流行雜誌",
    image:
      "https://r2.web.moonshine.tw/msweb/moonshotai/presets/db12806f-a8c2-4bdb-82be-c185677fe77f.webp",
  },
  {
    id: "ads-food_photography",
    label: "食物",
    image:
      "https://r2.web.moonshine.tw/msweb/moonshotai/presets/c467a9ab-9075-4f09-b728-9b164a000a05.webp",
  },
  {
    id: "ads-luxury",
    label: "奢華",
    image:
      "https://r2.web.moonshine.tw/msweb/moonshotai/presets/c9dca3f9-dcf2-476a-8a4d-0abfb609f9e7.webp",
  },
  {
    id: "ads-real_estate",
    label: "不動產",
    image:
      "https://r2.web.moonshine.tw/msweb/moonshotai/presets/b74bc3dc-8e12-4ccc-ae22-700221e9bf12.webp",
  },
  {
    id: "ads-retail",
    label: "產品",
    image:
      "https://r2.web.moonshine.tw/msweb/moonshotai/presets/512c4005-908f-466d-a1c0-6adfed549f41.webp",
  },
  {
    id: "artstyle-abstract",
    label: "抽象",
    image:
      "https://r2.web.moonshine.tw/msweb/moonshotai/presets/a3490753-fdc7-4bc2-b8ee-5cb405a35e37.webp",
  },
  {
    id: "artstyle-abstract_expressionism",
    label: "抽象表現",
    image:
      "https://r2.web.moonshine.tw/msweb/moonshotai/presets/ed90d23f-b7d5-417e-bfd3-62e7354133a4.webp",
  },
  {
    id: "artstyle-art_deco",
    label: "裝飾藝術",
    image:
      "https://r2.web.moonshine.tw/msweb/moonshotai/presets/cee2ed99-3645-411a-be05-add97daf9868.webp",
  },
  {
    id: "artstyle-art_nouveau",
    label: "新藝術運動",
    image:
      "https://r2.web.moonshine.tw/msweb/moonshotai/presets/5dbf3b36-ceb3-4232-967f-cd48662f1d76.webp",
  },
  {
    id: "artstyle-constructivist",
    label: "構成主義",
    image:
      "https://r2.web.moonshine.tw/msweb/moonshotai/presets/93efa7b7-b92f-4b9b-aa96-df784e0d9368.webp",
  },
  {
    id: "artstyle-cubist",
    label: "立體主義",
    image:
      "https://r2.web.moonshine.tw/msweb/moonshotai/presets/c7086ba1-4e27-4668-89fd-7f48cfdbd4bb.webp",
  },
  {
    id: "artstyle-expressionist",
    label: "表現主義",
    image:
      "https://r2.web.moonshine.tw/msweb/moonshotai/presets/26bbc261-7703-4f84-ac40-aaad04997658.webp",
  },
  {
    id: "artstyle-graffiti",
    label: "街頭塗鴉",
    image:
      "https://r2.web.moonshine.tw/msweb/moonshotai/presets/7524f563-611d-4da5-b6af-a3af9fe5c665.webp",
  },
  {
    id: "artstyle-hyperrealism",
    label: "超寫實主義",
    image:
      "https://r2.web.moonshine.tw/msweb/moonshotai/presets/2424cf2f-f2ea-4680-a18d-28bed1b1dc50.webp",
  },
  {
    id: "artstyle-impressionist",
    label: "印象派",
    image:
      "https://r2.web.moonshine.tw/msweb/moonshotai/presets/7b76c402-5cf1-4fe5-abc3-8bea068c7c25.webp",
  },
  {
    id: "artstyle-pointillism",
    label: "點彩畫派",
    image:
      "https://r2.web.moonshine.tw/msweb/moonshotai/presets/332c8c3d-9f13-4d98-a352-d0753100e805.webp",
  },
  {
    id: "artstyle-pop_art",
    label: "普普藝術",
    image:
      "https://r2.web.moonshine.tw/msweb/moonshotai/presets/879cab91-079d-4b7c-b650-ffacd8bc85c9.webp",
  },
  {
    id: "artstyle-psychedelic",
    label: "迷幻藝術",
    image:
      "https://r2.web.moonshine.tw/msweb/moonshotai/presets/c197a3d5-f2b0-425d-b0c4-a0549203a92c.webp",
  },
  {
    id: "artstyle-renaissance",
    label: "文藝復興",
    image:
      "https://r2.web.moonshine.tw/msweb/moonshotai/presets/3bd26fb4-8998-445d-a74f-70b9d05aba8e.webp",
  },
  {
    id: "artstyle-steampunk",
    label: "蒸氣龐克",
    image:
      "https://r2.web.moonshine.tw/msweb/moonshotai/presets/dc202110-a569-45a0-8375-fc30ffb3f3bd.webp",
  },
  {
    id: "artstyle-surrealist",
    label: "超現實主義",
    image:
      "https://r2.web.moonshine.tw/msweb/moonshotai/presets/76517185-8c72-49b4-88f4-73a19b2844cc.webp",
  },
  {
    id: "artstyle-typography",
    label: "字體排印",
    image:
      "https://r2.web.moonshine.tw/msweb/moonshotai/presets/e9f1c521-c088-4b01-8caf-ff2499d17b0d.webp",
  },
  {
    id: "artstyle-watercolor",
    label: "水彩",
    image:
      "https://r2.web.moonshine.tw/msweb/moonshotai/presets/b2bf2c62-0680-4ba6-b582-68a64f08b249.webp",
  },
  {
    id: "futuristic-biomechanical",
    label: "生化",
    image:
      "https://r2.web.moonshine.tw/msweb/moonshotai/presets/832735b0-af62-4c03-9372-fcd72493cdd2.webp",
  },
  {
    id: "futuristic-biomechanical_cyberpunk",
    label: "生化賽博龐克",
    image:
      "https://r2.web.moonshine.tw/msweb/moonshotai/presets/cb4176f7-1d3d-4950-9259-d85edb26f8bf.webp",
  },
  {
    id: "futuristic-cybernetic",
    label: "模控",
    image:
      "https://r2.web.moonshine.tw/msweb/moonshotai/presets/4bd69ff1-c267-4fae-a716-17f8aefd990d.webp",
  },
  {
    id: "futuristic-cybernetic_robot",
    label: "模控機械",
    image:
      "https://r2.web.moonshine.tw/msweb/moonshotai/presets/f2f58655-ccf7-4722-b7fd-8c2a9e4cb604.webp",
  },
  {
    id: "futuristic-cyberpunk_cityscape",
    label: "賽博龐克市容",
    image:
      "https://r2.web.moonshine.tw/msweb/moonshotai/presets/93bbea76-7b68-4d9e-b7ec-40ce4e98b4c8.webp",
  },
  {
    id: "futuristic-futuristic",
    label: "未來",
    image:
      "https://r2.web.moonshine.tw/msweb/moonshotai/presets/a4d879c9-2569-48cd-8946-20e2d3831c2a.webp",
  },
  {
    id: "futuristic-retro_cyberpunk",
    label: "復古賽博龐克",
    image:
      "https://r2.web.moonshine.tw/msweb/moonshotai/presets/bc7d99fc-f5d2-43fa-aff3-7674e49f0455.webp",
  },
  {
    id: "futuristic-retro_futurism",
    label: "復古未來",
    image:
      "https://r2.web.moonshine.tw/msweb/moonshotai/presets/c411203e-470c-473f-873e-50ff5a1f559a.webp",
  },
  {
    id: "futuristic-sci-fi",
    label: "科幻",
    image:
      "https://r2.web.moonshine.tw/msweb/moonshotai/presets/ae60a469-cfef-44f4-8de0-39e85864ecf8.webp",
  },
  {
    id: "futuristic-vaporwave",
    label: "蒸汽波",
    image:
      "https://r2.web.moonshine.tw/msweb/moonshotai/presets/57cbdca0-ab38-4cf5-bcac-7e1042c5ab20.webp",
  },
  {
    id: "game-bubble_bobble",
    label: "泡泡龍遊戲",
    image:
      "https://r2.web.moonshine.tw/msweb/moonshotai/presets/9549f077-9687-4c92-9ae1-6299bc65bc1c.webp",
  },
  {
    id: "game-cyberpunk_game",
    label: "蒸氣龐克遊戲",
    image:
      "https://r2.web.moonshine.tw/msweb/moonshotai/presets/7e61da1c-7c51-480f-9972-1d19b833d8fc.webp",
  },
  {
    id: "game-fighting_game",
    label: "格鬥遊戲",
    image:
      "https://r2.web.moonshine.tw/msweb/moonshotai/presets/27eae80d-3924-449e-805d-56df95cb48d5.webp",
  },
  {
    id: "game-gta",
    label: "俠盜獵車手",
    image:
      "https://r2.web.moonshine.tw/msweb/moonshotai/presets/f0b5b97a-20d3-437d-8629-c6d78cc53e2b.webp",
  },
  {
    id: "game-mario",
    label: "超級瑪利歐",
    image:
      "https://r2.web.moonshine.tw/msweb/moonshotai/presets/910e07ec-a653-48e0-bcbd-25e3e8f83204.webp",
  },
  {
    id: "game-minecraft",
    label: "當個創世神",
    image:
      "https://r2.web.moonshine.tw/msweb/moonshotai/presets/828174eb-d48c-4deb-bef3-5c0167677645.webp",
  },
  {
    id: "game-pokemon",
    label: "寶可夢",
    image:
      "https://r2.web.moonshine.tw/msweb/moonshotai/presets/184196dd-d7c0-4a61-a623-477d6db6064d.webp",
  },
  {
    id: "game-retro_arcade",
    label: "大型機台",
    image:
      "https://r2.web.moonshine.tw/msweb/moonshotai/presets/ca20f17c-6e28-40e6-904f-95cb02475abe.webp",
  },
  {
    id: "game-retro_game",
    label: "復古遊戲",
    image:
      "https://r2.web.moonshine.tw/msweb/moonshotai/presets/19d796ab-9408-43eb-9c54-6014ea74dc52.webp",
  },
  {
    id: "game-rpg_fantasy_game",
    label: "奇幻RPG",
    image:
      "https://r2.web.moonshine.tw/msweb/moonshotai/presets/c5b2b38c-6345-46d1-94d7-b594336ae8e7.webp",
  },
  {
    id: "game-strategy_game",
    label: "策略遊戲",
    image:
      "https://r2.web.moonshine.tw/msweb/moonshotai/presets/0948b335-e8da-416c-b545-3cc0ff952bfd.webp",
  },
  {
    id: "game-streetfighter",
    label: "快打旋風",
    image:
      "https://r2.web.moonshine.tw/msweb/moonshotai/presets/ddda86e8-9104-4ddb-8a72-2932db94edf6.webp",
  },
  {
    id: "game-zelda",
    label: "薩爾達",
    image:
      "https://r2.web.moonshine.tw/msweb/moonshotai/presets/23505d33-edfe-4e4a-8223-462e11f55d27.webp",
  },
  {
    id: "misc-architectural",
    label: "建築學",
    image:
      "https://r2.web.moonshine.tw/msweb/moonshotai/presets/99172417-5dbf-494e-8e0a-b360252f1973.webp",
  },
  {
    id: "misc-disco",
    label: "迪斯可",
    image:
      "https://r2.web.moonshine.tw/msweb/moonshotai/presets/d2fb4d73-d9a6-4501-9f72-a631f5f83f4b.webp",
  },
  {
    id: "misc-dreamscape",
    label: "夢境",
    image:
      "https://r2.web.moonshine.tw/msweb/moonshotai/presets/31f5efca-0ae1-4694-b569-d1a07261dd02.webp",
  },
  {
    id: "misc-dystopian",
    label: "反烏托邦",
    image:
      "https://r2.web.moonshine.tw/msweb/moonshotai/presets/08b44c34-0d56-4d9c-a10a-e7f7b3df23e8.webp",
  },
  {
    id: "misc-fairy_tale",
    label: "童話",
    image:
      "https://r2.web.moonshine.tw/msweb/moonshotai/presets/e9e9c23b-e6c3-480b-b7ce-4ed9414ec19f.webp",
  },
  {
    id: "misc-gothic",
    label: "歌德",
    image:
      "https://r2.web.moonshine.tw/msweb/moonshotai/presets/e8182d1b-5422-4a7d-a4ca-89258598e7b7.webp",
  },
  {
    id: "misc-grunge",
    label: "油漬搖滾",
    image:
      "https://r2.web.moonshine.tw/msweb/moonshotai/presets/3d16cf88-61f9-43dc-9ca7-d3af96c3d31d.webp",
  },
  {
    id: "misc-horror",
    label: "恐怖",
    image:
      "https://r2.web.moonshine.tw/msweb/moonshotai/presets/9de84770-2095-4005-a296-ea3b3d854e12.webp",
  },
  {
    id: "misc-kawaii",
    label: "可愛",
    image:
      "https://r2.web.moonshine.tw/msweb/moonshotai/presets/dec1ff84-dada-41e6-9d61-76b6103e1885.webp",
  },
  {
    id: "misc-lovecraftian",
    label: "洛夫克拉夫特",
    image:
      "https://r2.web.moonshine.tw/msweb/moonshotai/presets/60acbbb3-9c95-40a4-8caf-ef87153896bc.webp",
  },
  {
    id: "misc-macabre",
    label: "毛骨悚然",
    image:
      "https://r2.web.moonshine.tw/msweb/moonshotai/presets/1f8dbd40-0bd0-4e7a-a124-ff306d402841.webp",
  },
  {
    id: "misc-manga",
    label: "日本漫畫",
    image:
      "https://r2.web.moonshine.tw/msweb/moonshotai/presets/ce22317c-6fa4-4b08-b543-9670d3252600.webp",
  },
  {
    id: "misc-metropolis",
    label: "都會",
    image:
      "https://r2.web.moonshine.tw/msweb/moonshotai/presets/860f21ef-dc17-40a5-b1e8-279d8fca651c.webp",
  },
  {
    id: "misc-minimalist",
    label: "極簡主義",
    image:
      "https://r2.web.moonshine.tw/msweb/moonshotai/presets/12e8182b-8486-44e5-a142-fe08adf4341e.webp",
  },
  {
    id: "misc-monochrome",
    label: "黑白",
    image:
      "https://r2.web.moonshine.tw/msweb/moonshotai/presets/80732a23-d042-41c0-9365-5f250f556c9f.webp",
  },
  {
    id: "misc-nautical",
    label: "航海",
    image:
      "https://r2.web.moonshine.tw/msweb/moonshotai/presets/ebdccfbf-c708-4332-8382-9724437de829.webp",
  },
  {
    id: "misc-space",
    label: "宇航",
    image:
      "https://r2.web.moonshine.tw/msweb/moonshotai/presets/d3633388-1df2-477a-b60a-a8ed47863144.webp",
  },
  {
    id: "misc-stained_glass",
    label: "彩繪玻璃",
    image:
      "https://r2.web.moonshine.tw/msweb/moonshotai/presets/0a88b7d6-5b2e-4571-9814-b084df9f3c40.webp",
  },
  {
    id: "misc-techwear_fashion",
    label: "機能服飾",
    image:
      "https://r2.web.moonshine.tw/msweb/moonshotai/presets/86f3d8a6-8574-445a-9f1b-b6a0ff3ea8aa.webp",
  },
  {
    id: "misc-tribal",
    label: "部落",
    image:
      "https://r2.web.moonshine.tw/msweb/moonshotai/presets/c7e2d0df-f831-4454-8af9-ac71bbc14383.webp",
  },
  {
    id: "misc-zentangle",
    label: "禪繞",
    image:
      "https://r2.web.moonshine.tw/msweb/moonshotai/presets/e3fa81f1-fda5-49ea-bc62-8b7f224e5689.webp",
  },
  {
    id: "papercraft-collage",
    label: "拼貼",
    image:
      "https://r2.web.moonshine.tw/msweb/moonshotai/presets/b0bdb0b2-8931-440c-ba24-fdf271735db3.webp",
  },
  {
    id: "papercraft-flat_papercut",
    label: "剪紙",
    image:
      "https://r2.web.moonshine.tw/msweb/moonshotai/presets/bffdb5a3-ce6c-4d83-b9e0-48373469721c.webp",
  },
  {
    id: "papercraft-kirigami",
    label: "日式剪紙",
    image:
      "https://r2.web.moonshine.tw/msweb/moonshotai/presets/c5fceb9d-f2be-4db3-a5cd-761ba6b303b1.webp",
  },
  {
    id: "papercraft-paper_mache",
    label: "紙漿",
    image:
      "https://r2.web.moonshine.tw/msweb/moonshotai/presets/03469bc9-3717-4932-a8c7-a9ce75e31722.webp",
  },
  {
    id: "papercraft-paper_quilling",
    label: "捲紙",
    image:
      "https://r2.web.moonshine.tw/msweb/moonshotai/presets/0ac1ae3f-a9cd-42d8-869e-d7974d5aaacb.webp",
  },
  {
    id: "papercraft-papercut_collage",
    label: "剪紙拼貼",
    image:
      "https://r2.web.moonshine.tw/msweb/moonshotai/presets/3a22a6db-b2b3-465d-b64f-fe883fa7d003.webp",
  },
  {
    id: "papercraft-papercut_shadow_box",
    label: "紙雕投影",
    image:
      "https://r2.web.moonshine.tw/msweb/moonshotai/presets/84bc2c38-5d24-42d2-bcac-9f131081c171.webp",
  },
  {
    id: "papercraft-stacked_papercut",
    label: "堆疊剪紙",
    image:
      "https://r2.web.moonshine.tw/msweb/moonshotai/presets/47f98287-05ba-438a-8893-9c4167e7e979.webp",
  },
  {
    id: "papercraft-thick_layered_papercut",
    label: "深層堆疊剪紙",
    image:
      "https://r2.web.moonshine.tw/msweb/moonshotai/presets/2ae0c9b4-3340-4e0c-b36c-a9b5c89d8084.webp",
  },
  {
    id: "photo-alien",
    label: "異形",
    image:
      "https://r2.web.moonshine.tw/msweb/moonshotai/presets/3738e062-1ed8-40d0-a4bc-8a6d66370e31.webp",
  },
  {
    id: "photo-film_noir",
    label: "黑色電影",
    image:
      "https://r2.web.moonshine.tw/msweb/moonshotai/presets/f0ebcab9-c10f-4c58-ab75-ab18b859f8a5.webp",
  },
  {
    id: "photo-hdr",
    label: "HDR",
    image:
      "https://r2.web.moonshine.tw/msweb/moonshotai/presets/a8a6e038-a3a3-4a33-bb97-bed707c9fb83.webp",
  },
  {
    id: "photo-long_exposure",
    label: "長曝攝影",
    image:
      "https://r2.web.moonshine.tw/msweb/moonshotai/presets/191bd693-4d24-45dc-9662-60076acb06bc.webp",
  },
  {
    id: "photo-neon_noir",
    label: "新黑色電影",
    image:
      "https://r2.web.moonshine.tw/msweb/moonshotai/presets/9308c560-bcf7-4a08-94d6-2f9ed30b10a0.webp",
  },
  {
    id: "photo-silhouette",
    label: "剪影",
    image:
      "https://r2.web.moonshine.tw/msweb/moonshotai/presets/1057e046-1a84-4a72-a70a-476e47f53d1c.webp",
  },
  {
    id: "photo-tilt-shift",
    label: "移軸攝影",
    image:
      "https://r2.web.moonshine.tw/msweb/moonshotai/presets/b6f0fe6a-7135-48aa-a0f4-8f5ed22900c8.webp",
  },
  {
    id: "cinematic-diva",
    label: "影視",
    image:
      "https://r2.web.moonshine.tw/msweb/moonshotai/presets/9da0aa44-5ebf-4acb-acf1-f1be5a18ebbe.webp",
  },
  {
    id: "abstract_expressionism",
    label: "抽象表現主義",
    image:
      "https://r2.web.moonshine.tw/msweb/moonshotai/presets/180b9b80-9e5a-4d3c-852d-1b459d38079a.webp",
  },
  {
    id: "academia",
    label: "學院",
    image:
      "https://r2.web.moonshine.tw/msweb/moonshotai/presets/77df1e72-c0f9-40a5-8bc8-3341286f4785.webp",
  },
  {
    id: "action_figure",
    label: "可動玩具",
    image:
      "https://r2.web.moonshine.tw/msweb/moonshotai/presets/3c1dd975-19eb-4aa1-a14e-9d8ebbb15bb8.webp",
  },
  {
    id: "adorable_3d_character",
    label: "3D角色",
    image:
      "https://r2.web.moonshine.tw/msweb/moonshotai/presets/7eacb266-ebf2-4c7e-be5c-7f7ff5b04194.webp",
  },
  {
    id: "astral_aura",
    label: "星體光環",
    image:
      "https://r2.web.moonshine.tw/msweb/moonshotai/presets/98e1038b-943e-4eff-8e16-1c6708f77a35.webp",
  },
  {
    id: "avant-garde",
    label: "前衛",
    image:
      "https://r2.web.moonshine.tw/msweb/moonshotai/presets/accac534-eed0-4093-8b24-90b98469b82e.webp",
  },
  {
    id: "baroque",
    label: "巴洛克",
    image:
      "https://r2.web.moonshine.tw/msweb/moonshotai/presets/6b15d372-0fba-4c27-8519-9e0d006e3f10.webp",
  },
  {
    id: "bauhaus-style_poster",
    label: "包浩斯",
    image:
      "https://r2.web.moonshine.tw/msweb/moonshotai/presets/e265900d-7b29-4d2d-889d-7673a983b880.webp",
  },
  {
    id: "blueprint_schematic_drawing",
    label: "藍圖",
    image:
      "https://r2.web.moonshine.tw/msweb/moonshotai/presets/cd9f4f0d-00b8-4887-acf4-34abf86fe245.webp",
  },
  {
    id: "caricature",
    label: "諷刺畫",
    image:
      "https://r2.web.moonshine.tw/msweb/moonshotai/presets/c89a001f-ea58-4099-8378-c57ac05b203c.webp",
  },
  {
    id: "cel_shaded_art",
    label: "賽璐璐",
    image:
      "https://r2.web.moonshine.tw/msweb/moonshotai/presets/e9a111d8-9435-41a1-92ed-fb8a38c6619a.webp",
  },
  {
    id: "character_design_sheet",
    label: "角色設計圖",
    image:
      "https://r2.web.moonshine.tw/msweb/moonshotai/presets/a4e699ff-a995-4ace-b68b-be781622aa61.webp",
  },
  {
    id: "classicism_art",
    label: "古典主義",
    image:
      "https://r2.web.moonshine.tw/msweb/moonshotai/presets/6560c282-6041-4e52-a87c-c758f60e2d2a.webp",
  },
  {
    id: "color_field_painting",
    label: "色域繪畫",
    image:
      "https://r2.web.moonshine.tw/msweb/moonshotai/presets/08769a68-c91c-47f4-b5a3-7095b08ad3f3.webp",
  },
  {
    id: "colored_pencil_art",
    label: "色鉛筆",
    image:
      "https://r2.web.moonshine.tw/msweb/moonshotai/presets/30db3edd-052b-48ab-b57e-28f84c13d7dd.webp",
  },
  {
    id: "conceptual_art",
    label: "概念藝術",
    image:
      "https://r2.web.moonshine.tw/msweb/moonshotai/presets/d4706b5f-29cf-4634-82c0-08410869dcae.webp",
  },
  {
    id: "dadaism",
    label: "達達主義",
    image:
      "https://r2.web.moonshine.tw/msweb/moonshotai/presets/65d90fa4-4ef1-4f72-9c93-58eed3e3d059.webp",
  },
  {
    id: "dark_fantasy",
    label: "黑暗奇幻",
    image:
      "https://r2.web.moonshine.tw/msweb/moonshotai/presets/a9068265-81ca-4a02-8a6a-fbda0ee28ee1.webp",
  },
  {
    id: "dark_moody_atmosphere",
    label: "陰沉氣氛",
    image:
      "https://r2.web.moonshine.tw/msweb/moonshotai/presets/298575da-ef4f-4cb0-83a4-305864fe0a9e.webp",
  },
  {
    id: "dmt_art_style",
    label: "迷幻",
    image:
      "https://r2.web.moonshine.tw/msweb/moonshotai/presets/e083e9cd-6c64-415c-aad0-6c9b708f8cf2.webp",
  },
  {
    id: "doodle_art",
    label: "塗鴉",
    image:
      "https://r2.web.moonshine.tw/msweb/moonshotai/presets/a820bd2f-3b71-4bbe-ba17-ccc17d52eb48.webp",
  },
  {
    id: "double_exposure",
    label: "雙重曝光",
    image:
      "https://r2.web.moonshine.tw/msweb/moonshotai/presets/39031696-1d12-4dfe-891c-c988fbf330e5.webp",
  },
  {
    id: "dripping_paint_splatter_art",
    label: "滴濺",
    image:
      "https://r2.web.moonshine.tw/msweb/moonshotai/presets/ce82f921-dbaf-4716-b48a-c8e22af54827.webp",
  },
  {
    id: "faded_polaroid_photo",
    label: "褪色立可拍",
    image:
      "https://r2.web.moonshine.tw/msweb/moonshotai/presets/1dc0725b-6ba8-4d90-9515-f6d9973ecf3c.webp",
  },
  {
    id: "fauvism",
    label: "野獸派",
    image:
      "https://r2.web.moonshine.tw/msweb/moonshotai/presets/04f5fee3-dfee-4499-bdc1-0df8c969f6b0.webp",
  },
  {
    id: "flat_2d_art",
    label: "平面2D",
    image:
      "https://r2.web.moonshine.tw/msweb/moonshotai/presets/ad878ab3-0e79-49e9-b6db-522cc5056b41.webp",
  },
  {
    id: "fortnite_art_style",
    label: "Fortnite",
    image:
      "https://r2.web.moonshine.tw/msweb/moonshotai/presets/9c43b7f9-bc2f-4e4a-9311-a237d5c98170.webp",
  },
  {
    id: "glitchcore",
    label: "故障",
    image:
      "https://r2.web.moonshine.tw/msweb/moonshotai/presets/3e9f4d51-f9bc-4ffc-97c7-1b5574bbd851.webp",
  },
  {
    id: "glo-fi",
    label: "冷波",
    image:
      "https://r2.web.moonshine.tw/msweb/moonshotai/presets/522c5c35-2748-41a3-b67c-c5b336ce8182.webp",
  },
  {
    id: "harlem_renaissance_art",
    label: "哈林文藝復興",
    image:
      "https://r2.web.moonshine.tw/msweb/moonshotai/presets/06c0ac80-5d2c-45ef-bfcf-2187c61e1b32.webp",
  },
  {
    id: "high_fashion",
    label: "高級時裝",
    image:
      "https://r2.web.moonshine.tw/msweb/moonshotai/presets/9269d8dd-aa39-4493-a9cb-184183337c6f.webp",
  },
  {
    id: "idyllic",
    label: "田園",
    image:
      "https://r2.web.moonshine.tw/msweb/moonshotai/presets/a257c68d-cb46-4373-92f4-9ffdda0d96f4.webp",
  },
  {
    id: "infographic_drawing",
    label: "資訊圖表",
    image:
      "https://r2.web.moonshine.tw/msweb/moonshotai/presets/4199f31e-7b77-4b56-972a-c738ae158373.webp",
  },
  {
    id: "japanese_ink_drawing",
    label: "日式水墨",
    image:
      "https://r2.web.moonshine.tw/msweb/moonshotai/presets/37d79a14-2992-4b16-89e5-86c56a3774c2.webp",
  },
  {
    id: "knolling_photography",
    label: "擺放攝影",
    image:
      "https://r2.web.moonshine.tw/msweb/moonshotai/presets/f7e8e572-fa33-405b-8747-21034d3f32d3.webp",
  },
  {
    id: "logo_design",
    label: "商標",
    image:
      "https://r2.web.moonshine.tw/msweb/moonshotai/presets/96890a3a-35d5-4322-b56f-87d97b631ec4.webp",
  },
  {
    id: "luxurious_elegance",
    label: "奢華高貴",
    image:
      "https://r2.web.moonshine.tw/msweb/moonshotai/presets/041e0b83-8caa-4cd2-a3b8-910e8fc16bdd.webp",
  },
  {
    id: "macro_photography",
    label: "微型攝影",
    image:
      "https://r2.web.moonshine.tw/msweb/moonshotai/presets/30e1813f-5e79-4015-b4e0-be9ecad1e158.webp",
  },
  {
    id: "mandola_art",
    label: "曼陀羅",
    image:
      "https://r2.web.moonshine.tw/msweb/moonshotai/presets/6927987b-05f4-4d13-8455-824d085d8384.webp",
  },
  {
    id: "marker_drawing",
    label: "奇異筆",
    image:
      "https://r2.web.moonshine.tw/msweb/moonshotai/presets/68a20d9f-ed59-4398-a167-281650cd8cac.webp",
  },
  {
    id: "medievalism",
    label: "中世紀",
    image:
      "https://r2.web.moonshine.tw/msweb/moonshotai/presets/a044f69c-9619-463f-a8a6-cef32587053f.webp",
  },
  {
    id: "neo-baroque",
    label: "新巴洛克",
    image:
      "https://r2.web.moonshine.tw/msweb/moonshotai/presets/b0c42e15-d746-4b6f-86e8-771b94487563.webp",
  },
  {
    id: "neo-byzantine",
    label: "新拜占庭",
    image:
      "https://r2.web.moonshine.tw/msweb/moonshotai/presets/b76f3f7e-7e2b-444a-81c7-8bd9cbf0c41f.webp",
  },
  {
    id: "neo-futurism",
    label: "新未來主義",
    image:
      "https://r2.web.moonshine.tw/msweb/moonshotai/presets/4088d96b-9db5-4c42-9a06-eaa2e57a3f9c.webp",
  },
  {
    id: "neo-impressionism",
    label: "新印象派",
    image:
      "https://r2.web.moonshine.tw/msweb/moonshotai/presets/79965315-8cbc-4e73-a400-1012ccbed19c.webp",
  },
  {
    id: "neo-rococo",
    label: "新洛可可",
    image:
      "https://r2.web.moonshine.tw/msweb/moonshotai/presets/24b43a5a-f709-446f-b0d3-57cffb9303dc.webp",
  },
  {
    id: "neoclassicism",
    label: "新古典主義",
    image:
      "https://r2.web.moonshine.tw/msweb/moonshotai/presets/aa450618-4d08-43f9-8cfe-5fb6bb1366c5.webp",
  },
  {
    id: "op_art",
    label: "奧普藝術",
    image:
      "https://r2.web.moonshine.tw/msweb/moonshotai/presets/133e6b04-473e-49c8-8010-356153553538.webp",
  },
  {
    id: "ornate_and_intricate",
    label: "華麗繁複",
    image:
      "https://r2.web.moonshine.tw/msweb/moonshotai/presets/c6303c56-c9aa-4d7c-99d5-bb71df2c7773.webp",
  },
  {
    id: "pencil_sketch_drawing",
    label: "鉛筆素描",
    image:
      "https://r2.web.moonshine.tw/msweb/moonshotai/presets/743e967b-e414-4335-ac2d-5496b0a16311.webp",
  },
  {
    id: "simple_vector_art",
    label: "向量",
    image:
      "https://r2.web.moonshine.tw/msweb/moonshotai/presets/fa6ed816-3f97-4dfb-a464-b38887edad9b.webp",
  },
  {
    id: "sketchup",
    label: "CAD",
    image:
      "https://r2.web.moonshine.tw/msweb/moonshotai/presets/41fb2f35-3f73-480f-941e-b5ebc0f15cce.webp",
  },
  {
    id: "suprematism",
    label: "至上主義",
    image:
      "https://r2.web.moonshine.tw/msweb/moonshotai/presets/de724b46-c778-4e0d-b16c-7797d7d71d67.webp",
  },
  {
    id: "terragen",
    label: "自然地景",
    image:
      "https://r2.web.moonshine.tw/msweb/moonshotai/presets/aa1b00a8-8894-44bf-acba-ec3aaca6f134.webp",
  },
  {
    id: "tranquil_relaxing_atmosphere",
    label: "寧靜氛圍",
    image:
      "https://r2.web.moonshine.tw/msweb/moonshotai/presets/9466222c-4a3c-4ac9-be23-cf5ac5524b5c.webp",
  },
  {
    id: "sticker_designs",
    label: "貼紙",
    image:
      "https://r2.web.moonshine.tw/msweb/moonshotai/presets/92c06df4-9221-4c66-9415-0e6f5d4c0cec.webp",
  },
  {
    id: "vibrant_rim_light",
    label: "鮮豔勾邊光",
    image:
      "https://r2.web.moonshine.tw/msweb/moonshotai/presets/16a71422-dbfc-4c0d-8aa4-0290cd6397c3.webp",
  },
  {
    id: "volumetric_lighting",
    label: "體積光",
    image:
      "https://r2.web.moonshine.tw/msweb/moonshotai/presets/a36e4bb8-730d-45fd-996d-e9dc82410794.webp",
  },
  {
    id: "whimsical_and_playful",
    label: "異想天開",
    image:
      "https://r2.web.moonshine.tw/msweb/moonshotai/presets/17b38246-9bc0-4ad1-9beb-cf21235e37bc.webp",
  },
];

export const models = [
  {
    command: "XL",
    title: "SDXL",
    image: "https://r2.web.moonshine.tw/msweb/moonshotai/models/xl.jpg",
  },
  {
    command: "CT",
    title: "插畫 CT",
    image: "https://r2.web.moonshine.tw/msweb/moonshotai/models/ct.jpg",
  },
  {
    command: "PR",
    title: "寫實 PR",
    image: "https://r2.web.moonshine.tw/msweb/moonshotai/models/pr.webp",
  },
  {
    command: "CM",
    title: "漫畫 CM",
    image: "https://r2.web.moonshine.tw/msweb/moonshotai/models/cm.jpg",
  },
  {
    command: "PC",
    title: "寫實人像 PC",
    image: "https://r2.web.moonshine.tw/msweb/moonshotai/models/pc.png",
  },
  {
    command: "MJ",
    title: "MJ",
    image: "https://r2.web.moonshine.tw/msweb/moonshotai/models/mj.jpg",
  },
];
